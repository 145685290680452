.logo-svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(23%);
    z-index: -1;
  }

@media screen and (max-width: 1200px) {
    .logo-svg{
        width: 100%;
        height: 100%;
        position: relative;
        bottom: 0;  /* Changed to bottom */
        right: 0;
        z-index: -1;
        overflow: visible;
    }
}