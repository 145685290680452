.home-page {
  
  .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 40%;
      max-height: 90%;
  }

  h1 {
      color: #fff;
      font-size: 56px;
      line-height: 53px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;

      &:before {
          content: '<h1>';
          font-family: 'La Belle Aurore', cursive;
          color: #00A9FF;
          font-size: 18px;
          position: absolute;
          margin-top: -40px;
          left: -15px;
          opacity: 0.6;
      }

      &:after {
          content: '<h1/>';
          font-family: 'La Belle Aurore', cursive;
          color: #00A9FF;
          font-size: 18px;
          position: absolute;
          margin-top: 18px;
          margin-left: 20px;
          animation: fadeIn 1s 1s backwards; /* Changed from 1.7s to 1s */
          opacity: 0.6;
      }

      img {
          margin-left: 20px;
          opacity: 0;
          width: 60px; //32
          height: auto;
          animation: rotateIn 1s linear both;
          animation-delay: 1s; /* Changed from 1.4s to 1s */
      }
  }

  h2 {
      color: #8d8d8d;
      margin-top: 20px;
      font-weight: 400;
      font-size: 11px;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
      color: #00A9FF;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #00A9FF;
      margin-right: 15px;
      margin-top: 25px;
      float: left;
      animation: fadeIn 1s 1.8s backwards; 
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        background: #00A9FF;
        color: #0d1430;
        font-weight: 600;
      }
  }
}

@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1:before,
  .home-page h1:after {
      display: none;
  }
  
  .home-page {
    overflow-x: hidden;
    .text-zone{
      position: initial;
      width: 100%;
      transform: none;
      padding: 15px;
      box-sizing: border-box;
    } 
    .flat-button {
      float: left;
      display: block;
      margin: 20px 10px 0 5px;
      width: 125px;
      text-align: center;
    }
        
    h1{
      font-size: 43px;
    }

    .logo-container {
      position: relative;
      width: 100px;
      height: auto;
      top: 50px;
      right: 0;
      box-sizing: border-box;
      margin: auto;
      left: 0;
    
      svg {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
      }
    }
  }
}
